// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_container__qYw\\+Y{min-height:calc(100vh - var(--wpp-os-header-height));background-color:var(--wpp-grey-color-100)}", "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,uBACE,oDAAA,CACA,0CAAA","sourcesContent":[".container {\n  min-height: calc(100vh - var(--wpp-os-header-height));\n  background-color: var(--wpp-grey-color-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "App_container__qYw+Y"
};
export default ___CSS_LOADER_EXPORT___;
