import {
  WppIconButton,
  WppIconMore,
  WppMenuContext,
  WppMenuItem,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useMiroBoardApi } from 'api/miro/queries/useMiroBoardApi'
import { Flex } from 'components/common/flex/Flex'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPemission'
import { CreateOrCopyBoardSideModal } from 'pages/miroBoard/components/CreateOrCopyBoardSideModal'
import styles from 'pages/miroBoard/MiroBoard.module.scss'
import { useAuthMiro } from 'pages/miroBoard/utils/useAuthMiro'
import { MiroBoard as Board } from 'types/miro'

const MiroBoard = () => {
  const [board, setBoard] = useState<MayBeNull<Board>>()
  const [boardToCopy, setBoardToCopy] = useState<MayBeNull<Board>>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const [queryParams] = useSearchParams()
  const { hasPermission } = useHasPermission()

  const menu = useMemo(() => [{ title: t('miro.create_board'), disabled: false, exec: () => createBoard() }], [t])

  const accountId = (queryParams.get('state') ?? queryParams.get('accountId'))!

  const { isLoading, isError, status, data: boards } = useMiroBoardApi({ params: { accountId } })
  useAuthMiro(status === 'error' && isError)

  const createBoard = () => {
    setBoardToCopy(null)
    setIsOpen(true)
  }

  useEffect(() => {
    if (hasPermission(Permission.OS_MIRO_MANAGE) && !boards.length && !isError && status === 'success') {
      setBoard(null)
      setIsOpen(true)
    }

    if (!board && boards.length) {
      setBoard(boards[0])
    }
  }, [setIsOpen, boards, board, hasPermission, setBoard, isError, status])

  return (
    <>
      <Flex className={styles.root} direction="column" gap={16}>
        <Flex direction="row" justify="between">
          {(board || isLoading) && (
            <WppTypography>
              {t('miro.board')}:{' '}
              {(board && <span className={styles.boardName}>{board?.name}</span>) ||
                (isLoading && <WppSpinner size="m" />)}
            </WppTypography>
          )}

          {!board && !isLoading && <WppTypography>{}</WppTypography>}

          {hasPermission(Permission.OS_MIRO_MANAGE) && (
            <Flex justify="end">
              <WppMenuContext>
                <WppIconButton slot="trigger-element">
                  <WppIconMore />
                </WppIconButton>
                <>
                  {menu.map(item => (
                    <WppMenuItem key={item.title} disabled={item?.disabled} onClick={() => item.exec()}>
                      {item.title}
                    </WppMenuItem>
                  ))}
                </>
              </WppMenuContext>
            </Flex>
          )}
        </Flex>
        <div className={styles.contentWrapper}>
          <CreateOrCopyBoardSideModal
            isOpen={isOpen}
            accountId={accountId}
            board={boardToCopy}
            handleCloseModal={() => {
              setIsOpen(false)
            }}
          />
          {(board && (
            <iframe
              src={board?.embed_url}
              title={board?.name}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          )) ?? <WppTypography>{t('miro.boards_not_found')}</WppTypography>}
        </div>
      </Flex>
    </>
  )
}
export default MiroBoard
