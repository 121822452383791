// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MiroBoard_root__tlYDZ{height:calc(100vh - var(--wpp-os-header-height));padding:24px 28px}.MiroBoard_boardMenu__m7o6R{min-width:320px}.MiroBoard_boardName__ejqLi{font-weight:600;font-size:20px;text-transform:uppercase}.MiroBoard_contentWrapper__hD8P-{display:flex;align-items:center;justify-content:center;width:100%;height:100%}.MiroBoard_contentWrapper__hD8P- iframe{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/pages/miroBoard/MiroBoard.module.scss"],"names":[],"mappings":"AAAA,uBACE,gDAAA,CACA,iBAAA,CAGF,4BACE,eAAA,CAGF,4BACE,eAAA,CACA,cAAA,CACA,wBAAA,CAGF,iCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CAEA,wCACE,UAAA,CACA,WAAA","sourcesContent":[".root {\n  height: calc(100vh - var(--wpp-os-header-height));\n  padding: 24px 28px;\n}\n\n.boardMenu {\n  min-width: 320px;\n}\n\n.boardName {\n  font-weight: 600;\n  font-size: 20px;\n  text-transform: uppercase;\n}\n\n.contentWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n\n  iframe {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MiroBoard_root__tlYDZ",
	"boardMenu": "MiroBoard_boardMenu__m7o6R",
	"boardName": "MiroBoard_boardName__ejqLi",
	"contentWrapper": "MiroBoard_contentWrapper__hD8P-"
};
export default ___CSS_LOADER_EXPORT___;
