import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { hasPermission } from 'utils/permissions'

export const useHasPermission = () => {
  const { osContext } = useOs()

  const { permissions, tenant } = osContext

  return {
    hasPermission: useCallback(
      (permission?: string) => !permission || hasPermission(permissions, permission, tenant.azMeta.organizationsId),
      [permissions, tenant.azMeta.organizationsId],
    ),
  }
}
