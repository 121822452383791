import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { miroApi } from 'api'

const apiInstances = [miroApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { osApi } = useOs()

  useEffect(() => {
    const interceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}
