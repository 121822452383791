import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { MayBeNull } from '@wpp-open/core'
import { useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { useCopyMiroBoardApi } from 'api/miro/mutations/useCopyMiroBoardApi'
import { useCreateMiroBoardApi } from 'api/miro/mutations/useCreateMiroBoardApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { FormSideModal } from 'components/surface/sideModal/FormSideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/miroBoard/components/CreateOrCopyBoardSideModal.module.scss'
import { useAuthMiro } from 'pages/miroBoard/utils/useAuthMiro'
import { MiroBoard, MiroForm } from 'types/miro'

interface Props {
  isOpen: boolean
  board?: MayBeNull<MiroBoard>
  accountId: string
  handleCloseModal: (needRefetch?: boolean) => void
}

const validationSchema = zod.object({
  name: zod.string().trim().min(2).max(150),
  description: zod.string().trim().min(2).max(280),
})

export const CreateOrCopyBoardSideModal = ({ isOpen, board, accountId, handleCloseModal }: Props) => {
  const form = useForm<MiroForm>({
    validationSchema,
    defaultValues: { id: board?.id, name: board?.name ?? null, description: board?.description, accountId },
  })
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form
  const { enqueueToast } = useToast()
  const queryClient = useQueryClient()
  const { mutateAsync: handleCopy } = useCopyMiroBoardApi()
  const { mutateAsync: handleCreate } = useCreateMiroBoardApi()
  const authError = useRef({ isError: false })

  const { t } = useTranslation()

  const onSubmit = async (data: MiroForm) => {
    try {
      const payload = { ...data, accountId }
      board ? await handleCopy({ ...board, ...payload }) : await handleCreate(payload)
      await queryClient.invalidateQueries([ApiQueryKeys.MIRO])
      handleCloseModal()
      return data
    } catch (error: any) {
      authError.current.isError = error?.response?.status === 401 ?? false
      enqueueToast({
        message: t('common.errors.error'),
        type: 'error',
      })
    }
  }

  useAuthMiro(authError.current.isError)

  return (
    <FormProvider {...form}>
      <FormSideModal
        open={isOpen}
        formProps={{
          onSubmit: handleSubmit(onSubmit),
        }}
        onWppSideModalClose={() => {
          handleCloseModal(false)
        }}
        onWppSideModalCloseComplete={() => {
          reset()
        }}
      >
        <WppTypography slot="header">
          {board ? t('miro.clone_board_title') : t('miro.create_new_board_title')}
        </WppTypography>
        <div slot="body">
          <FormTextInput
            name="name"
            className={styles.inputField}
            labelConfig={{ text: t('miro.form.name.label') }}
            required
          />
          <FormTextInput
            name="description"
            className={styles.inputField}
            labelConfig={{ text: t('miro.form.description.label') }}
            required
          />
        </div>
        <Flex slot="actions" gap={12} justify="end">
          <WppButton
            variant="secondary"
            size="s"
            type="button"
            onClick={() => {
              handleCloseModal()
            }}
          >
            {t('miro.close')}
          </WppButton>
          <WppButton variant="primary" size="s" type="submit" loading={isSubmitting} data-testid="save-button">
            {board ? t('miro.clone') : t('miro.save')}
          </WppButton>
        </Flex>
      </FormSideModal>
    </FormProvider>
  )
}
