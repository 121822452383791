import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { MicroAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'
import { StrictMode } from 'react'

import { App } from 'app/App'
import 'i18n/i18n'
import { ApiProvider } from 'providers/api/ApiProvider'
import { ToastProvider } from 'providers/toast/ToastProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const Root = (props: MicroAppCustomProps) => {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ToastProvider>
          <OsProvider {...props}>
            <ApiProvider>
              <App />
            </ApiProvider>
          </OsProvider>
        </ToastProvider>
      </QueryClientProvider>
    </StrictMode>
  )
}
