import { WppSideModal } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, ComponentProps, forwardRef } from 'react'

import { Portal } from 'components/common/portal/Portal'
import { useKeepUnmountedOnClose } from 'components/surface/utils'

type Props = ComponentPropsWithoutRef<typeof WppSideModal> & {
  formProps?: ComponentProps<'form'>
}

export const FormSideModal = forwardRef<HTMLWppSideModalElement, Props>((props, ref) => {
  const [shouldRender, { formProps, ...rest }] = useKeepUnmountedOnClose(props, 'onWppSideModalCloseComplete')

  return (
    <>
      {shouldRender && (
        <Portal>
          <form {...formProps}>
            <WppSideModal ref={ref} {...rest} />
          </form>
        </Portal>
      )}
    </>
  )
})
