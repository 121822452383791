// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Flex_root__CpI\\+x{display:flex}.Flex_inline__WXCJG{display:inline-flex}.Flex_column__lamIX{flex-direction:column}.Flex_align-center__eRe0c{align-items:center}.Flex_align-end__0LWbL{align-items:flex-end}.Flex_justify-center__lTd9k{justify-content:center}.Flex_justify-end__VcxCR{justify-content:flex-end}.Flex_justify-between__FugLF{justify-content:space-between}.Flex_justify-around__Hw8lj{justify-content:space-around}", "",{"version":3,"sources":["webpack://./src/components/common/flex/Flex.module.scss"],"names":[],"mappings":"AAAA,mBACE,YAAA,CAGF,oBACE,mBAAA,CAGF,oBACE,qBAAA,CAGF,0BACE,kBAAA,CAGF,uBACE,oBAAA,CAGF,4BACE,sBAAA,CAGF,yBACE,wBAAA,CAGF,6BACE,6BAAA,CAGF,4BACE,4BAAA","sourcesContent":[".root {\n  display: flex;\n}\n\n.inline {\n  display: inline-flex;\n}\n\n.column {\n  flex-direction: column;\n}\n\n.align-center {\n  align-items: center;\n}\n\n.align-end {\n  align-items: flex-end;\n}\n\n.justify-center {\n  justify-content: center;\n}\n\n.justify-end {\n  justify-content: flex-end;\n}\n\n.justify-between {\n  justify-content: space-between;\n}\n\n.justify-around {\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Flex_root__CpI+x",
	"inline": "Flex_inline__WXCJG",
	"column": "Flex_column__lamIX",
	"align-center": "Flex_align-center__eRe0c",
	"align-end": "Flex_align-end__0LWbL",
	"justify-center": "Flex_justify-center__lTd9k",
	"justify-end": "Flex_justify-end__VcxCR",
	"justify-between": "Flex_justify-between__FugLF",
	"justify-around": "Flex_justify-around__Hw8lj"
};
export default ___CSS_LOADER_EXPORT___;
