import { useRef, TransitionEventHandler } from 'react'
import { useUpdate, usePrevious } from 'react-use'

import { noop } from 'utils/common'

export const useKeepUnmountedOnClose = <
  P extends {
    open?: boolean
    onTransitionEnd?: TransitionEventHandler
  },
>(
  props: P,
  onCloseCompletePropName: keyof P,
) => {
  const { open, onTransitionEnd, [onCloseCompletePropName]: onCloseCompleteHandler, ...rest } = props

  const prevOpen = usePrevious(open)
  const isClosingRef = useRef(false)
  const update = useUpdate()

  if (prevOpen && !open) {
    isClosingRef.current = true
  }

  const shouldRender = open || isClosingRef.current

  return [
    shouldRender,
    {
      ...rest,
      open,
      /* 
        TODO: onWppModalCloseComplete and onWppSideModalCloseComplete are implemented incorrect in CL.
        To preserve original props for components we remap it here - noop for original prop and actual method call in right place.
        Should be highlighted to CL team and removed after fix. 
      */
      [onCloseCompletePropName]: noop,
      onTransitionEnd: e => {
        if (!open && e.propertyName === 'opacity') {
          const handler = onCloseCompleteHandler as () => any
          handler?.()

          isClosingRef.current = false
          update()
        }

        onTransitionEnd?.(e)
      },
    } as P,
  ] as const
}
