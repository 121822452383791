// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toast_container__kt2V9{z-index:10002}", "",{"version":3,"sources":["webpack://./src/providers/toast/Toast.module.scss"],"names":[],"mappings":"AAGA,wBACE,aAAA","sourcesContent":["// @TODO remove file after CL update\n@use 'scss/variables.scss';\n\n.container {\n  z-index: #{variables.$os-toast-z-index};\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Toast_container__kt2V9"
};
export default ___CSS_LOADER_EXPORT___;
