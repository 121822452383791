// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateOrCopyBoardSideModal_inputField__QQ0Xa{margin:16px 0}.CreateOrCopyBoardSideModal_inputField__QQ0Xa:first-child{margin:0}", "",{"version":3,"sources":["webpack://./src/pages/miroBoard/components/CreateOrCopyBoardSideModal.module.scss"],"names":[],"mappings":"AAAA,8CACE,aAAA,CAEA,0DACE,QAAA","sourcesContent":[".inputField {\n  margin: 16px 0;\n\n  &:first-child {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputField": "CreateOrCopyBoardSideModal_inputField__QQ0Xa"
};
export default ___CSS_LOADER_EXPORT___;
