import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuthMiroBoard } from 'api/miro/mutations/useAuthMiroBoard'
import { useAuthMiroBoardConfig } from 'api/miro/mutations/useAuthMiroBoardConfig'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { AuthMiroBoard } from 'types/miro'

export const useAuthMiro = (isErrorRequest: boolean) => {
  const [queryParams] = useSearchParams()
  const queryClient = useQueryClient()
  const { mutateAsync: authConfig } = useAuthMiroBoardConfig()
  const { mutateAsync: authMiroBoard } = useAuthMiroBoard()

  const getConfig = useCallback(
    async (isError: boolean) => {
      if (isError) {
        try {
          const code = queryParams.get('code')
          if (code) {
            const config: AuthMiroBoard = {
              code,
              team_id: queryParams.get('team_id'),
              client_id: queryParams.get('client_id'),
            }
            await authMiroBoard(config as AuthMiroBoard)
            await queryClient.invalidateQueries([ApiQueryKeys.MIRO])
          } else {
            const {
              data: { login_url },
            } = await authConfig(queryParams.get('accountId')!)
            window.location.replace(login_url)
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    [authMiroBoard, authConfig, queryParams, queryClient],
  )

  useEffect(() => {
    getConfig(isErrorRequest)
  }, [getConfig, authMiroBoard, authConfig, queryParams, isErrorRequest])
}
